import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useAxios } from '../../axios';
import { TarifOptionDto } from '../types';

type Props = {
    children?: ReactNode;
};

export function RatesProvider(props: Props): JSX.Element {
    const axios = useAxios();
    const [rates, setRates] = useState<TarifOptionDto[]>([]);

    const fetch = useCallback(async (): Promise<void> => {
        const rates = await axios.get<TarifOptionDto[]>('api/tarif-options');
        if (rates?.data) {
            setRates(rates.data);
        } else {
            setRates([]);
        }
    }, [axios]);

    useEffect(() => {
        if (rates == null || rates.length === 0) {
            fetch();
        }
    }, [rates, fetch]);

    return <RatesContext.Provider value={rates}>{props.children}</RatesContext.Provider>;
}

export const RatesContext = createContext<TarifOptionDto[]>([]);

export function useRates(): TarifOptionDto[] {
    const context = useContext(RatesContext);
    if (context == null) {
        throw new Error('InsuranceListContext.Provider is not set in the React component tree.');
    }
    return context;
}
