import { notification } from 'antd';
import React from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';
import { AxiosProvider } from './lib/axios';
import { DataProvider } from './lib/shared/data-context/data-context';
import { InsuranceListProvider } from './lib/shared/data-context/insurances-context';
import { App } from './main-layout';
import { RatesProvider } from './lib/shared/data-context';

const TRACKING_ID = 'G-QHGXL0LY7F';
ReactGA.initialize(TRACKING_ID);

export const AppWithProviders = (): React.ReactElement => {
    notification.config({ placement: 'bottomLeft' });

    return (
        <AxiosProvider>
            <DataProvider>
                <InsuranceListProvider>
                    <RatesProvider>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </RatesProvider>
                </InsuranceListProvider>
            </DataProvider>
        </AxiosProvider>
    );
};

export default AppWithProviders;
