import dayjs from 'dayjs';

export const dateFormat = ['DD.MM.YYYY', 'D.MM.YYYY', 'DD.M.YYYY', 'D.M.YYYY'];

export const insuranceStartDatePreset = (): dayjs.Dayjs => {
    return dayjs().add(1, 'day');
};

// eslint-disable-next-line
export const trimAllProps = (obj: any): void => {
    Object.keys(obj).forEach((k) => {
        if (typeof obj[k as keyof typeof obj] === 'string')
            obj[k as keyof typeof obj] = obj[k as keyof typeof obj]?.trim() ?? '';
    });

    switch (obj['insuranceOption']) {
        case 1:
            obj['currentInsureType'] = 'Haftpflicht';
            break;
        case 2:
            obj['currentInsureType'] = 'Teilkasko';
            break;
        case 3:
            obj['currentInsureType'] = 'Vollkasko';
            break;
    }

    switch (obj['comprehensive']) {
        case 1:
            obj['currentInsureDeductible'] = 0;
            break;
        case 2:
            obj['currentInsureDeductible'] = 300;
            break;
        case 3:
            obj['currentInsureDeductible'] = 500;
            break;
        case 5:
            obj['currentInsureDeductible'] = 1000;
            break;
    }
};
