import { Card, Select, Typography } from 'antd';
import Title from 'antd/es/typography/Title';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useData, useInsuranceList } from '../../../lib/shared/data-context';
import { MainLayout, SelectableCard } from '../../components';
import { DottedInfoBox } from '../../components/dotted-info-box/dotted-info-box';
import { Routing } from '../../routing';
import './welcome-view.scss';

export const WelcomeView: React.FC = () => {
    const navigate = useNavigate();
    const { data, setData } = useData();
    const insuranceList = useInsuranceList();

    const [isContinueDisabled, setContinueDisabled] = useState<boolean>(true);

    const goNext = (): void => {
        navigate(Routing.tarifSelection);
    };

    const updateInsuranceOption = (value: number): void => {
        setData({ ...data, insuranceOption: value, selectedTarif: 0 });
    };

    const updateComprehensive = (value: number): void => {
        setData({ ...data, comprehensive: value, selectedTarif: 0 });
    };

    const updateCurrentInsurance = (currentInsurance: string | null): void => {
        setData({ ...data, currentInsurance: currentInsurance });
    };

    useEffect(() => {
        setContinueDisabled(
            !data.currentInsurance ||
                (data.insuranceOption === 3 && data.comprehensive <= 1) ||
                data.insuranceOption === 0
        );
    }, [data.insuranceOption, data.comprehensive, data.currentInsurance, data]);

    return (
        <MainLayout
            currentProgress={1}
            showBackButton={false}
            showContinueButton
            showSubmitButton={false}
            continueButtonDisabled={isContinueDisabled}
            goNext={goNext}
        >
            <div>
                <Card className='info-card text-centered'>
                    <Title className='title'>{'Willkommen'}</Title>
                    <div className='text'>
                        {'Schön, dass du da bist! Wir helfen dir bei der Gestaltung'}
                        <br />
                        {'deiner HappySurance-Versicherung. Halte deinen '}
                        <b>{'Fahrzeugschein'}</b>
                        <br />
                        {' und deine '}
                        <b>{'Bankdaten'}</b>
                        {' bereit.'}
                    </div>
                </Card>
                <div className='welcome-view-choice-container'>
                    <Title className='title left'>{'Deine aktuelle Versicherungssituation'}</Title>
                    <Typography className='text label'>
                        {'Wie ist dein Fahrzeug versichert?'}
                    </Typography>
                    <div className='welcome-view-choices'>
                        <SelectableCard
                            selected={data.insuranceOption === 1}
                            onSelect={(): void => updateInsuranceOption(1)}
                        >
                            <Typography>{'Haftpflicht'}</Typography>
                        </SelectableCard>
                        <SelectableCard
                            selected={data.insuranceOption === 2}
                            onSelect={(): void => updateInsuranceOption(2)}
                        >
                            <Typography>{'Teilkasko'}</Typography>
                        </SelectableCard>
                        <SelectableCard
                            selected={data.insuranceOption === 3}
                            onSelect={(): void => updateInsuranceOption(3)}
                        >
                            <Typography>{'Vollkasko'}</Typography>
                        </SelectableCard>
                    </div>
                    <div className='flex-centered'>
                        {data.insuranceOption > 0 && (
                            <DottedInfoBox
                                className='centered'
                                style={{ marginTop: '2rem', maxWidth: '960px' }}
                            >
                                {data.insuranceOption === 1 && (
                                    <span>
                                        {
                                            'Du hast nur eine Haftpflichtversicherung, möchtest dich aber trotzdem gegen die kleinen Missgeschicke im Alltag absichern?'
                                        }
                                    </span>
                                )}
                                {data.insuranceOption === 2 && (
                                    <span>
                                        {
                                            'Du hast nur eine Teilkasko-Versicherung, möchtest dich aber trotzdem gegen die kleinen Missgeschicke im Alltag absichern?'
                                        }
                                    </span>
                                )}
                                {data.insuranceOption === 3 && (
                                    <span>
                                        {
                                            'Super, dass du eine Vollkasko hast! Die schützt dich vor den richtig großen Schäden. Die kleinen Missgeschicke bleiben aber - je nach Höhe deines Selbstbehalts - an dir selbst kleben. Bei uns kannst du '
                                        }
                                        <b>{'deinen Selbstbehalt versichern'}</b>
                                        {
                                            ', so dass du keine finanziellen Einbußen hast. Des weiteren ruinieren kleine Schäden - je nach Versicherungstarif - deinen Schadenfreiheitsrabatt. Was die Vollkasko nicht weiß, macht die Vollkasko nicht heiß:'
                                        }
                                        <br />
                                        <b>
                                            {
                                                'Wir von HappySurance retten deinen Schadensfreiheitsrabatt.'
                                            }
                                        </b>
                                    </span>
                                )}
                            </DottedInfoBox>
                        )}
                    </div>
                </div>
                <div>
                    <div>
                        <div className='text label'>
                            {'Beim welchem Versicherer ist dein Fahrzeug versichert?'}
                        </div>
                        <Select
                            className='inner-field welcome-view-insurance-select'
                            value={data.currentInsurance}
                            placeholder={'Bitte Versicherungsunternehmen auswählen'}
                            options={insuranceList
                                .sort((a, b) => a.localeCompare(b))
                                .map((item) => ({
                                    label: item,
                                    value: item,
                                }))}
                            onSelect={updateCurrentInsurance}
                            showSearch
                            filterOption={(search, item): boolean =>
                                (item?.label ?? '').toUpperCase().includes(search.toUpperCase())
                            }
                            disabled={insuranceList?.length === 0}
                            status={
                                !data.currentInsurance || data.currentInsurance === ''
                                    ? 'error'
                                    : ''
                            }
                        />
                    </div>
                </div>
                {data.insuranceOption === 3 && (
                    <div style={{ marginTop: '4rem' }}>
                        <div className='text label'>{'Wie hoch ist deine Selbstbeteiligung?'}</div>
                        <div className='welcome-view-comprehensive-choices welcome-view-choice-container'>
                            <SelectableCard
                                selected={data.comprehensive === 1}
                                onSelect={(): void => updateComprehensive(1)}
                            >
                                <Typography>{'0 €'}</Typography>
                            </SelectableCard>
                            <SelectableCard
                                selected={data.comprehensive === 2}
                                onSelect={(): void => updateComprehensive(2)}
                            >
                                <Typography>{'300 €'}</Typography>
                            </SelectableCard>
                            <SelectableCard
                                selected={data.comprehensive === 3}
                                onSelect={(): void => updateComprehensive(3)}
                            >
                                <Typography>{'500 €'}</Typography>
                            </SelectableCard>
                            <SelectableCard
                                selected={data.comprehensive === 5}
                                onSelect={(): void => updateComprehensive(5)}
                            >
                                <Typography>{'1.000 € oder höher'}</Typography>
                            </SelectableCard>
                        </div>
                    </div>
                )}
                {data.insuranceOption === 3 && data.comprehensive === 1 && (
                    <div className='contentTile flex-centered'>
                        <DottedInfoBox
                            className='centered'
                            style={{ marginTop: '2rem', maxWidth: '960px' }}
                        >
                            <Title className='title'>
                                {'Leider können wir dir keine Versicherung anbieten!'}
                            </Title>
                            <Typography className='text'>
                                {
                                    'Denn HappySurance versichert deinen Selbstbehalt - und du hast keinen. Wir empfehlen dir, den '
                                }
                                <b>
                                    {
                                        'Selbstbehalt deiner regulären Kfz-Versicherung auf 1.000 € einzustellen'
                                    }
                                </b>
                                {' und den '}
                                <b>
                                    {
                                        'Tollpatsch-Tarif bei HappySurance mit 1.000 € pro Schadensfall'
                                    }
                                </b>
                                {' abzuschließen.'}
                            </Typography>
                        </DottedInfoBox>
                    </div>
                )}
            </div>
        </MainLayout>
    );
};
