import Title from 'antd/es/typography/Title';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useData } from '../../../lib/shared/data-context';
import { useWindowSize } from '../../../lib/shared/hooks';
import { DropdownTile, InputTile, MainLayout } from '../../components';
import { Routing } from '../../routing';
import { isDate, isEmail, isName, isNumber, isPhoneNumber, isStreetAndNumber } from '../validation';
import './user-details-view.scss';

const toDate = (value: string): Date => {
    const segments = value.split('.');
    return new Date(parseInt(segments[2]), parseInt(segments[1]) - 1, parseInt(segments[0]));
};

const isDateAtLeast18YearsAgo = (value: Date): boolean => {
    const today = new Date(new Date(Date.now()).toDateString());
    const atLeast = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    return value <= atLeast;
};

const isDateAtLeastTomorrow = (value: Date): boolean => {
    const today = new Date(new Date(Date.now()).toDateString());
    const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    return value >= tomorrow;
};

export const UserDetailsView: React.FC = () => {
    // used to rerender on window resize -> new position of dateRef -> datePickerPosition
    useWindowSize();

    const navigate = useNavigate();
    const { data, setData } = useData();

    const [isContinueDisabled, setContinueDisabled] = useState<boolean>(true);

    const goBack = (): void => {
        navigate(Routing.terms);
    };

    const goNext = (): void => {
        navigate(Routing.ibanInput);
    };

    const {
        salutation,
        firstName,
        lastName,
        birthday,
        streetAndNumber,
        city,
        zipCode,
        email,
        phoneNumber,
        startDate,
    } = data;

    useEffect(() => {
        setContinueDisabled(
            !data.salutation ||
                !data.firstName ||
                !data.lastName ||
                !data.birthday ||
                !isDate(data.birthday) ||
                !isDateAtLeast18YearsAgo(toDate(data.birthday)) ||
                !data.startDate ||
                !isDate(data.startDate) ||
                !isDateAtLeastTomorrow(toDate(data.startDate)) ||
                !data.streetAndNumber ||
                !data.city ||
                data.zipCode?.length !== 5 ||
                !isEmail(data.email)
        );
    }, [data]);

    return (
        <MainLayout
            currentProgress={5}
            showBackButton
            showContinueButton
            showSubmitButton={false}
            continueButtonDisabled={isContinueDisabled}
            goNext={goNext}
            goBack={goBack}
        >
            <div className='user-details-view'>
                <Title className='title left'>
                    {'Wer bist du und wie können wir dich erreichen?'}
                </Title>
                <DropdownTile
                    label='Anrede'
                    items={salutations}
                    selected={salutation}
                    invalid={!salutation || salutation === ''}
                    placeholder={'Bitte Anrede auswählen'}
                    onChange={(value): void => setData({ ...data, salutation: value })}
                />
                <InputTile
                    label='Vorname'
                    value={firstName}
                    onChange={(firstName): void => {
                        if (!isName(firstName)) return;
                        setData({ ...data, firstName });
                    }}
                    invalid={!firstName || firstName === ''}
                    label2='Nachname'
                    value2={lastName}
                    onChange2={(lastName): void => {
                        if (!isName(lastName)) return;
                        setData({ ...data, lastName });
                    }}
                    invalid2={!lastName || lastName === ''}
                />
                <InputTile
                    label='Geburtsdatum (Mindestalter 18 J.)'
                    value={birthday}
                    onChange={(birthday): void => {
                        birthday = birthday.trim();
                        if (
                            birthday.length > 10 ||
                            (birthday.length > 0 && !/[.\d]+/.test(birthday))
                        )
                            return;
                        setData({ ...data, birthday });
                    }}
                    placeholder='TT.MM.JJJJ'
                    invalid={
                        !birthday || !isDate(birthday) || !isDateAtLeast18YearsAgo(toDate(birthday))
                    }
                />
                <InputTile
                    label='Straße und Hausnummer'
                    value={streetAndNumber}
                    onChange={(streetAndNumber): void => {
                        streetAndNumber = streetAndNumber.trimStart();
                        if (!isStreetAndNumber(streetAndNumber)) return;
                        setData({ ...data, streetAndNumber });
                    }}
                    invalid={!streetAndNumber || streetAndNumber === ''}
                />
                <InputTile
                    label='Postleitzahl'
                    value={zipCode}
                    onChange={(zipCode): void => {
                        if (zipCode.length > 5 || !isNumber(zipCode)) return;
                        setData({ ...data, zipCode });
                    }}
                    invalid={!zipCode || zipCode.length !== 5}
                    label2='Stadt'
                    value2={city}
                    invalid2={!city || city === ''}
                    onChange2={(city): void => {
                        city = city.trimStart();
                        if (!isStreetAndNumber(city)) return;
                        setData({ ...data, city });
                    }}
                />
                <InputTile
                    label='E-Mail'
                    value={email}
                    onChange={(email): void => {
                        email = email.trim();
                        if (email.length > 320) return;
                        setData({ ...data, email });
                    }}
                    invalid={!email || !isEmail(email)}
                />
                <InputTile
                    label='Telefonnummer (optional)'
                    value={phoneNumber}
                    onChange={(phoneNumber): void => {
                        if (!isPhoneNumber(phoneNumber)) return;
                        setData({ ...data, phoneNumber });
                    }}
                />
                <InputTile
                    label='Ab wann soll dein Auto versichert werden? (frühestens morgen)'
                    value={startDate}
                    onChange={(startDate): void => {
                        startDate = startDate.trim();
                        if (
                            startDate.length > 10 ||
                            (startDate.length > 0 && !/[.\d]+/.test(startDate))
                        )
                            return;
                        setData({ ...data, startDate });
                    }}
                    placeholder='TT.MM.JJJJ'
                    invalid={
                        !startDate ||
                        !isDate(startDate) ||
                        !isDateAtLeastTomorrow(toDate(startDate))
                    }
                />
            </div>
        </MainLayout>
    );
};

const salutations = ['Herr', 'Frau', 'Ohne'];
