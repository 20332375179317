import Title from 'antd/es/typography/Title';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAxios } from '../../../lib/axios';
import { useData } from '../../../lib/shared/data-context';
import { useIbanValidator } from '../../../lib/shared/hooks';
import { InputTile, MainLayout, RequiredCheckbox } from '../../components';
import { Routing } from '../../routing';

export const IbanInputView: React.FC = () => {
    const axios = useAxios();
    const navigate = useNavigate();
    const { data, setData } = useData();
    const { isIbanValid, normalizeIban } = useIbanValidator();

    const [isContinueDisabled, setContinueDisabled] = useState<boolean>(true);

    const goBack = (): void => {
        navigate(Routing.userDetails);
    };

    const goNext = (): void => {
        navigate(Routing.summary);
    };

    useEffect(() => {
        setContinueDisabled(
            !data.bank ||
                !data.sepa ||
                !data.iban ||
                data.iban.length < 22 ||
                !isIbanValid(data.iban)
        );
    }, [data, data.iban, data.sepa, data.bank, isIbanValid]);

    const updateIban = async (value: string): Promise<void> => {
        const iban = normalizeIban(value, false);
        if (!isIbanValid(iban) || iban.length >= 35) {
            setData({ ...data, iban: iban });
            return;
        }

        const newData = { ...data, iban: iban };
        try {
            const response = await axios.get(`api/validate-iban?iban=${iban}`);
            if (response.data && response.data.status === 'valid') {
                newData.bank = response.data.bank;
            } else {
                newData.bank = null;
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
        setData(newData);
    };

    return (
        <MainLayout
            currentProgress={6}
            showBackButton
            showContinueButton
            showSubmitButton={false}
            continueButtonDisabled={isContinueDisabled}
            goNext={goNext}
            goBack={goBack}
        >
            <div>
                <Title className='title left'>
                    {'Angaben zur Zahlung deines Versicherungsbeitrags'}
                </Title>
                <InputTile
                    label='IBAN des Kontos'
                    value={data.iban}
                    onChange={(iban): void => {
                        const normalized = iban.replaceAll(' ', '');
                        if (normalized.length > 22) {
                            updateIban(normalized.substring(0, 22));
                        } else {
                            updateIban(normalized);
                        }
                    }}
                    invalid={!data.iban || data.iban.length < 22 || !isIbanValid(data.iban)}
                    placeholder='DE12345678901234567890'
                />
                <RequiredCheckbox
                    checked={data.sepa}
                    onChange={(checked): void => setData({ ...data, sepa: checked })}
                >
                    <div className={'text'}>
                        {
                            'Ich erteile das SEPA-Lastschriftmandat für die KfZ-Versicherung zum Einzug der fälligen Beträge vom angegebenen Konto.'
                        }
                    </div>
                </RequiredCheckbox>
            </div>
        </MainLayout>
    );
};
