import { Navigate, Route, Routes } from 'react-router-dom';
import {
    CarDetailsView,
    IbanInputView,
    QuestionsView,
    ResultView,
    SubmissionView,
    SummaryView,
    TarifSelectionView,
    UserDetailsView,
    WelcomeView,
} from '../views';
import { Routing } from './routing';

export const RoutedContent = (): React.ReactElement | null => {
    return (
        <Routes>
            <Route path={Routing.landing} element={<WelcomeView />} />
            <Route path={Routing.tarifSelection} element={<TarifSelectionView />} />
            <Route path={Routing.carDetails} element={<CarDetailsView />} />
            <Route path={Routing.terms} element={<QuestionsView />} />
            <Route path={Routing.userDetails} element={<UserDetailsView />} />
            <Route path={Routing.ibanInput} element={<IbanInputView />} />
            <Route path={Routing.summary} element={<SummaryView />} />
            <Route path={Routing.submission} element={<SubmissionView />} />
            <Route path={Routing.success} element={<ResultView isAccepted={true} />} />
            <Route path={Routing.abort} element={<ResultView isAccepted={false} />} />

            <Route path='*' element={<Navigate to={Routing.landing} replace />} />
        </Routes>
    );
};
