import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Card, Input, Modal } from 'antd';
import Title from 'antd/es/typography/Title';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useData } from '../../../lib/shared/data-context';
import { MainLayout } from '../../components';
import { Routing } from '../../routing';
import { tertiaryColor } from '../../styles/theme';
import {
    isAlphaNumeric,
    isLetter,
    isNumber,
    isNumberPlate,
    isPartialNumberPlate,
} from '../validation';
import './car-details-view.scss';
import { CarSearchPopup } from './components';

export const CarDetailsView: React.FC = () => {
    const navigate = useNavigate();
    const { data, setData } = useData();

    const { manufactureNr, typeNr, carId, numberPlate, carSearchParameters } = data;

    const [isContinueDisabled, setContinueDisabled] = useState<boolean>(true);
    const [infoForHsnTsnOpen, setInfoForHsnTsnOpen] = useState<boolean>(false);
    const [infoForFinOpen, setInfoForFinOpen] = useState<boolean>(false);

    const goBack = (): void => {
        navigate(Routing.tarifSelection);
    };

    const goNext = (): void => {
        navigate(Routing.terms);
    };

    useEffect(() => {
        setContinueDisabled(
            manufactureNr?.length !== 4 ||
                typeNr?.length !== 3 ||
                carId?.length !== 17 ||
                !isNumberPlate(numberPlate)
        );
    }, [manufactureNr, typeNr, carId, numberPlate]);

    return (
        <MainLayout
            currentProgress={3}
            showBackButton
            showContinueButton
            showSubmitButton={false}
            continueButtonDisabled={isContinueDisabled}
            goNext={goNext}
            goBack={goBack}
        >
            <div className='car-detail-view'>
                <Title className='title left' style={{ marginLeft: '24px' }}>
                    {'Welches Auto möchtest Du versichern?'}
                </Title>
                <Card className='container-card'>
                    <div className='car-detail-numbers-container'>
                        <div
                            className='text label'
                            style={{ gridArea: 'label-hsn', margin: 'auto 0' }}
                        >
                            {'Herstellernummer (HSN)'}
                        </div>
                        <Button
                            className='car-detail-info-button'
                            onClick={(): void => setInfoForHsnTsnOpen(true)}
                            style={{ gridArea: 'info-hsn', marginTop: '4px' }}
                            tabIndex={-1}
                        >
                            <InfoCircleFilled style={{ color: tertiaryColor }} />
                        </Button>
                        <div
                            className='text label'
                            style={{ gridArea: 'label-tsn', margin: 'auto 0' }}
                        >
                            {'Typschlüsselnummer (TSN)'}
                        </div>
                        <Button
                            className='car-detail-info-button'
                            onClick={(): void => setInfoForHsnTsnOpen(true)}
                            style={{ gridArea: 'info-tsn', marginTop: '4px' }}
                            tabIndex={-1}
                        >
                            <InfoCircleFilled style={{ color: tertiaryColor }} />
                        </Button>
                        <Input
                            className='car-detail-number-input'
                            value={manufactureNr ?? ''}
                            onChange={(e): void => {
                                const manufactureNr = e.target.value;
                                if (manufactureNr.length > 4 || !isNumber(manufactureNr)) return;
                                setData({ ...data, manufactureNr });
                            }}
                            status={!manufactureNr || manufactureNr.length < 4 ? 'error' : ''}
                            style={{ gridArea: 'input-hsn' }}
                        />
                        <Input
                            className='car-detail-number-input'
                            value={typeNr ?? ''}
                            onChange={(e): void => {
                                const typeNr = e.target.value.toLocaleUpperCase();
                                if (typeNr.length > 3 || !(isNumber(typeNr) || isLetter(typeNr)))
                                    return;
                                setData({ ...data, typeNr });
                            }}
                            status={!typeNr || typeNr.length < 3 ? 'error' : ''}
                            style={{ gridArea: 'input-tsn' }}
                        />
                        <CarSearchPopup
                            searchParameters={carSearchParameters}
                            setSearchParameters={(carSearchParameters): void =>
                                setData({ ...data, carSearchParameters })
                            }
                            onChooseCar={(tsn, hsn): void => {
                                if (tsn !== typeNr || hsn !== manufactureNr)
                                    setData({ ...data, typeNr: tsn, manufactureNr: hsn });
                            }}
                        />
                    </div>
                </Card>
                <div className='car-details-noncard'>
                    <span className='text label' style={{ margin: 'auto 0' }}>
                        {'Fahrzeugidentifikationsnummer (FIN)'}
                    </span>
                    <Button
                        className='car-detail-info-button'
                        onClick={(): void => setInfoForFinOpen(true)}
                        style={{ float: 'right', marginTop: '4px' }}
                        tabIndex={-1}
                    >
                        <InfoCircleFilled style={{ color: tertiaryColor }} />
                    </Button>
                    <Input
                        value={carId ?? ''}
                        onChange={(e): void => {
                            const value = e.target.value.toLocaleUpperCase();
                            if (value.length > 17 || !isAlphaNumeric(value)) return;
                            setData({ ...data, carId: value });
                        }}
                        status={!carId || carId.length < 17 ? 'error' : ''}
                    />
                    <div className='text label' style={{ marginTop: '24px' }}>
                        {'Amtliches Kennzeichen'}
                    </div>
                    <Input
                        value={numberPlate ?? ''}
                        placeholder={'z.B. MA-XX2525 E'}
                        onChange={(e): void => {
                            const value = e.target.value.toLocaleUpperCase();
                            if (!isPartialNumberPlate(value)) return;
                            setData({ ...data, numberPlate: value });
                        }}
                        status={!numberPlate || !isNumberPlate(numberPlate) ? 'error' : ''}
                    />
                </div>
                <Modal
                    open={infoForHsnTsnOpen}
                    onCancel={(): void => setInfoForHsnTsnOpen(false)}
                    footer={<></>}
                    width={720}
                >
                    <Title level={3}>
                        <div className='title left'>
                            {'Herstellernummer und Typschlüsselnummer'}
                        </div>
                    </Title>
                    <div className='text'>
                        {
                            'Die Herstellernummer (HSN) und die Typschlüsselnummer (TSN) findest du in deinem Fahrzeugschein bzw. Fahrzeugbrief. Im Fahrzeugschein stehen die HSN (2.1) und TSN (2.2) wie folgt:'
                        }
                    </div>
                    <img alt='Fahrzeugschein' src='static/images/Fahrzeugschein.png' width={660} />
                    <div className='text'>
                        {
                            'Falls du deinen Fahrzeugschein nicht zur Hand hast, kannst du unser Fahrzeug-Suchtool nutzen, um dein Fahrzeug anhand des Herstellers, Modells usw. auszuwählen. Beachte bitte: Wenn deine TSN mit 000 beginnt, könnte es sich um ein Reimport-Fahrzeug handeln. Auch dann nutze bitte unser Suchtool, um ein vergleichbares Fahrzeug auszuwählen.'
                        }
                    </div>
                </Modal>
                <Modal
                    open={infoForFinOpen}
                    onCancel={(): void => setInfoForFinOpen(false)}
                    footer={<></>}
                    width={720}
                >
                    <Title level={3}>
                        <div className='title left'>{'Fahrzeugidentifikationsnummer'}</div>
                    </Title>
                    <div className='text'>
                        {
                            'Eine Fahrzeugidentifikationsnummer (kurz FIN) ist die international genormte, 17-stellige Seriennummer, mit der dein Auto eindeutig identifizierbar ist.'
                        }
                    </div>
                    <div className='text'>
                        {
                            'Deine FIN findest du entweder in deinem Fahrzeugschein, eingestanzt in der Bodenplatte oder auf der rechten Seite vor dem Vordersitz deines Autos.'
                        }
                    </div>
                </Modal>
            </div>
        </MainLayout>
    );
};
