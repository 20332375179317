import './progress-bar.scss';

interface Props {
    value: number;
    max: number;
}

export const Progressbar: React.FC<Props> = ({ value, max }: Props) => {
    return (
        <div className='progress-bar-container'>
            <div className='progress-bar-label'>
                <div style={{ flex: '1 0 0' }} />
                {`${value} von ${max}`}
            </div>
            <div className='progress-bar-background'>
                <div
                    className='progress-bar-section'
                    style={{ width: `${(100 * value) / max}%` }}
                ></div>
            </div>
        </div>
    );
};
