export class Routing {
    static readonly landing = '/landing';
    static readonly tarifSelection = '/tarifs';
    static readonly carDetails = '/car-details';
    static readonly terms = '/terms';
    static readonly userDetails = '/user-details';
    static readonly ibanInput = '/iban-check';
    static readonly summary = '/summary';
    static readonly submission = '/submission';
    static readonly success = '/success';
    static readonly abort = '/cancel';
}
