const normalizeIban = (iban: string | null, removeDePrefix = false): string => {
    if (iban === null) {
        return '';
    }
    iban = iban.trim();

    const loweredIban = iban.toLowerCase();
    if (removeDePrefix && loweredIban.startsWith('de')) {
        iban = iban.substring(2, iban.length);
    }

    return iban.replace(' ', '').replace('-', '');
};

const isIbanValid = (iban: string | null): boolean => {
    if (typeof iban !== 'string' || iban === null || iban === '') {
        return false;
    }

    const country = iban.slice(0, 2).toUpperCase();
    const checkDigit = iban.slice(2, 4);
    const bban = iban.slice(4);

    // Validate IBAN check digit
    const validateAlphanumeric = `${bban}${country}${checkDigit}`;
    const validateNumberic = lettersToDigits(validateAlphanumeric);
    if (modulo97(validateNumberic) !== 1) {
        return false;
    }

    return true;
};

/**
 * Checksum calculation method for IBAN
 */
const modulo97 = (value: string): number => {
    let checksum = Number(value.slice(0, 2));
    let fragment: string;
    for (let offset = 2; offset < value.length; offset += 7) {
        fragment = `${checksum}${value.substring(offset, offset + 7)}`;
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
};

/**
 * Convert letters to digits (ASCII code minus 55)
 */
const lettersToDigits = (string: string): string => {
    return string.replace(/[a-z]/gi, (letter) => String(letter.charCodeAt(0) - 55));
};

export const useIbanValidator = (): IbanValidatorReturn => {
    return { isIbanValid, normalizeIban };
};

interface IbanValidatorReturn {
    isIbanValid: (iban: string | null) => boolean;
    normalizeIban: (iban: string | null, removeDePrefix: boolean) => string;
}
