import { Card } from 'antd';
import { ReactNode } from 'react';
import './tarif-card.scss';

interface Props {
    children?: ReactNode;
    className?: string;
    disabled?: boolean;
    selected?: boolean;
    onSelect?: () => void | Promise<void>;
}

export const TarifCard: React.FC<Props> = ({
    children,
    className,
    disabled,
    selected,
    onSelect,
}: Props) => {
    let classString = 'tarif-card';
    if (selected) {
        classString = 'tarif-card-selected';
    }

    if (disabled) {
        classString = `${classString} tarif-card-disabled`;
    }

    if (className) {
        classString = `${classString} ${className}`;
    }

    const select = (): void | Promise<void> => {
        if (!disabled && onSelect) {
            onSelect();
        }
    };

    return (
        <Card className={classString} onClick={select}>
            {children}
        </Card>
    );
};
