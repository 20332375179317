import { Col, Input, Row } from 'antd';
import { ReactNode } from 'react';
import '../../styles/shared.scss';

export const InputTile: React.FC<Props> = ({
    label,
    value,
    onChange,
    flex,
    placeholder,
    invalid,
    maxLength,
    addonBefore,
    label2,
    value2,
    onChange2,
    flex2,
    placeholder2,
    invalid2,
    maxLength2,
}: Props) => {
    if (label2 || value2 || onChange2 || invalid2 || placeholder2 || flex || flex2)
        if (!(label2 && onChange2)) throw new Error('props of the second(2) input missing');

    flex ??= 1;
    flex2 ??= 1;
    return (
        <div>
            <Row className='row'>
                <Col flex={flex}>
                    <div className='text label'>{label}</div>
                    <Input
                        placeholder={placeholder}
                        value={value ?? ''}
                        onChange={(e): void => onChange(e.target.value)}
                        status={invalid ? 'error' : ''}
                        addonBefore={addonBefore}
                        maxLength={maxLength}
                    />
                </Col>
                {onChange2 && (
                    <Col flex={flex2} className='second-column'>
                        <div className='text label'>{label2}</div>
                        <Input
                            placeholder={placeholder2}
                            value={value2 ?? ''}
                            onChange={(e): void => onChange2(e.target.value)}
                            status={invalid2 ? 'error' : ''}
                            maxLength={maxLength2}
                        />
                    </Col>
                )}
            </Row>
        </div>
    );
};

interface Props {
    label: ReactNode; // | string
    value: string | null;
    onChange: (value: string) => void;
    flex?: number;
    placeholder?: string;
    invalid?: boolean;
    maxLength?: number;
    addonBefore?: ReactNode | null;
    label2?: ReactNode; // | string
    value2?: string | null;
    onChange2?: (value: string) => void;
    flex2?: number;
    placeholder2?: string;
    invalid2?: boolean;
    maxLength2?: number;
}
