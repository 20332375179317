import { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import {
    CarDetailsDto,
    CheckboxesData,
    IbanInputData,
    TarifDto,
    TarifSelectionData,
    UserDetailsDto,
} from '../types';
import { dateFormat, insuranceStartDatePreset } from '../utils';

const initialValues: FormData = {
    comprehensive: 0,
    insuranceOption: 0,
    selectedTarif: 0,
    currentInsurance: null,
    startDate: insuranceStartDatePreset().format(dateFormat[0]),
    carId: null,
    manufactureNr: null,
    numberPlate: null,
    typeNr: null,
    carSearchParameters: {
        manufacturer: null,
        type: null,
        poweredBy: null,
        constructionYear: null,
        performanceKW: null,
    },
    isAccepted: [true, true, true, true],
    truthfulStatement: false,
    salutation: null,
    firstName: null,
    lastName: null,
    birthday: null,
    streetAndNumber: null,
    city: null,
    zipCode: null,
    email: null,
    phoneNumber: null,
    iban: null,
    sepa: false,
    status: null,
    bank: null,
};

export type FormData = TarifDto &
    TarifSelectionData &
    CarDetailsDto &
    UserDetailsDto &
    IbanInputData &
    CheckboxesData;

type DataOperations = {
    data: FormData;
    setData: (data: FormData) => void;
    reset: () => void;
};

type Props = {
    children?: ReactNode;
};

export function DataProvider(props: Props): JSX.Element {
    const [data, setData] = useState<FormData>(initialValues);

    const reset = useCallback((): void => {
        setData(initialValues);
    }, [setData]);

    return (
        <DataContext.Provider value={{ data, setData, reset }}>
            {props.children}
        </DataContext.Provider>
    );
}

export const DataContext = createContext<DataOperations | null>(null);

export function useData(): DataOperations {
    const context = useContext(DataContext);
    if (context == null) {
        throw new Error('DataContext.Provider is not set in the React component tree.');
    }
    return context;
}
