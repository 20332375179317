import { FilePdfOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import Title from 'antd/es/typography/Title';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAxios } from '../../../lib/axios';
import { useData } from '../../../lib/shared/data-context';
import { SubmitResultDto } from '../../../lib/shared/types';
import { trimAllProps } from '../../../lib/shared/utils';
import { MainLayout } from '../../components';
import { Routing } from '../../routing';

export const SubmissionView: React.FC = () => {
    const axios = useAxios();
    const navigate = useNavigate();
    const { data } = useData();
    const { isAccepted } = data;

    const [isContinueDisabled, setContinueDisabled] = useState<boolean>(true);

    const goBack = (): void => {
        navigate(Routing.summary);
    };

    const submit = async (): Promise<void> => {
        trimAllProps(data);
        data.email = data.email?.toLocaleLowerCase() ?? '';

        const response = await axios.post<SubmitResultDto>('api/submit', data);
        if (response.data.status === 'approved') {
            navigate(Routing.success);
        } else {
            navigate(Routing.abort);
        }
    };

    useEffect(() => {
        setContinueDisabled(!isAccepted.every((isAccepted) => isAccepted));
    }, [isAccepted]);

    return (
        <MainLayout
            currentProgress={8}
            showBackButton
            showContinueButton={false}
            showSubmitButton
            continueButtonDisabled={isContinueDisabled}
            goBack={goBack}
            submit={submit}
        >
            <div>
                <Title className='title left'>{'Fast geschafft!'}</Title>
                <Card className='info-card'>
                    <div>
                        <a
                            href='static/documents/Beratungsprotokoll.pdf'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <FilePdfOutlined />
                            <span className='text bold'>{' Beratungsprotokoll'}</span>
                        </a>
                    </div>
                    <div>
                        <a
                            href='static/documents/Allgemeine Versicherungsbedingungen.pdf'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <FilePdfOutlined />
                            <span className='text bold'>
                                {' Allgemeine Versicherungsbedingungen'}
                            </span>
                        </a>
                    </div>
                    <div>
                        <a
                            href='static/documents/Produktinformationsblatt Minikasko HappySurance[57].pdf'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <FilePdfOutlined />
                            <span className='text bold'>
                                {' Informationsblatt zu Versicherungsprodukten'}
                            </span>
                        </a>
                    </div>
                    <div>
                        <a
                            href='static/documents/Vorabinformationen zum Datenschutz.pdf'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <FilePdfOutlined />
                            <span className='text bold'>{' Vorabinformation zum Datenschutz'}</span>
                        </a>
                    </div>
                </Card>
                <div style={{ marginBottom: '24px', paddingLeft: '24px' }}>
                    {'Mit Klick auf '}
                    <b>{'"Kostenpflichtig abschließen"'}</b>
                    {' bestätige ich, dass ich die Dokumente zur Kenntnis genommen habe.'}
                </div>
            </div>
        </MainLayout>
    );
};
