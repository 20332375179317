import { Button } from 'antd';
import { ReactNode, useEffect, useRef } from 'react';
import { Progressbar } from '../progress-bar';
import './main-layout.scss';

type Props = {
    currentProgress: number;
    showBackButton: boolean;
    showContinueButton: boolean;
    continueButtonDisabled: boolean;
    showSubmitButton: boolean;
    goBack?: () => void;
    goNext?: () => void;
    submit?: () => void;
    children?: ReactNode;
};

export const MainLayout: React.FC<Props> = ({
    currentProgress,
    showBackButton,
    showContinueButton,
    continueButtonDisabled,
    showSubmitButton,
    goBack,
    goNext,
    submit,
    children,
}: Props) => {
    const anchor = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (anchor.current) {
            document.documentElement.scrollTop = anchor.current.offsetTop;
        }
    }, [anchor]);

    return (
        <div>
            <div ref={anchor} />
            {children}
            <Progressbar value={currentProgress} max={8} />
            <div className='navigation-container'>
                <div style={{ display: 'flex' }}>
                    {showBackButton && (
                        <Button className='secondary-button' onClick={goBack}>
                            {'Zurück'}
                        </Button>
                    )}
                    <div className='navigation-container-spacer' />
                </div>
                <div style={{ display: 'flex' }}>
                    <div className='navigation-container-spacer' />
                    {showContinueButton && (
                        <Button
                            className='primary-button'
                            type='primary'
                            disabled={continueButtonDisabled}
                            onClick={goNext}
                        >
                            {'Weiter'}
                        </Button>
                    )}
                    {showSubmitButton && (
                        <Button
                            className='primary-button accept-button-margin'
                            type='primary'
                            disabled={continueButtonDisabled}
                            onClick={submit}
                        >
                            {'Kostenpflichtig abschließen'}
                            <div style={{ height: '20px', marginBottom: '-20px' }}></div>
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
