import { Col, Row, Select } from 'antd';
import React from 'react';
import '../../styles/shared.scss';

export const DropdownTile: React.FC<Props> = ({
    label,
    items,
    selected,
    onChange,
    onSearch,
    flex,
    allowClear,
    invalid,
    placeholder,
    label2,
    items2,
    selected2,
    onChange2,
    onSearch2,
    flex2,
    allowClear2,
    invalid2,
    placeholder2,
}: Props) => {
    if (label2 || items2 || selected2 || onChange2 || onSearch2 || allowClear2 || flex || flex2)
        if (!(label2 && items2 && onChange2))
            throw new Error('props of the second(2) dropdown missing');
    flex ??= 1;
    flex2 ??= 1;

    const dropdownItems = items
        .sort((a, b) => a.localeCompare(b))
        .map((item) => ({
            label: item,
            value: item,
        }));

    items2 ??= [];
    const dropdownItems2 = items2
        .sort((a, b) => a.localeCompare(b))
        .map((item) => ({
            label: item,
            value: item,
        }));

    return (
        <div>
            <Row className='row'>
                <Col flex={flex}>
                    <div className='text label'>{label}</div>
                    <Select
                        className='inner-field'
                        value={selected}
                        options={dropdownItems}
                        onSelect={onChange}
                        placeholder={placeholder}
                        showSearch
                        filterOption={(search, item): boolean =>
                            (item?.label ?? '').toUpperCase().includes(search.toUpperCase())
                        }
                        onSearch={onSearch}
                        allowClear={allowClear}
                        onClear={(): void => onChange(null)}
                        disabled={dropdownItems.length === 0}
                        status={invalid ? 'error' : ''}
                    />
                </Col>
                {onChange2 && (
                    <Col flex={flex2} className='second-column'>
                        <div className='text label'>{label2}</div>
                        <Select
                            className='inner-field'
                            value={selected2}
                            options={dropdownItems2}
                            onSelect={onChange2}
                            placeholder={placeholder2}
                            showSearch
                            filterOption={(search, item): boolean =>
                                (item?.label ?? '').toUpperCase().includes(search.toUpperCase())
                            }
                            onSearch={onSearch2}
                            allowClear={allowClear2}
                            status={invalid2 ? 'error' : ''}
                            onClear={(): void => onChange2(null)}
                            disabled={dropdownItems2.length === 0}
                        />
                    </Col>
                )}
            </Row>
        </div>
    );
};

interface Props {
    label: string;
    items: string[];
    selected: string | null;
    onChange: (item: string | null) => void;
    onSearch?: (search: string) => void;
    flex?: number;
    allowClear?: boolean;
    invalid?: boolean;
    placeholder?: string;
    label2?: string;
    items2?: string[];
    selected2?: string | null;
    onChange2?: (item: string | null) => void;
    onSearch2?: (search: string) => void;
    flex2?: number;
    allowClear2?: boolean;
    invalid2?: boolean;
    placeholder2?: string;
}
