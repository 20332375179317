import { InfoCircleFilled } from '@ant-design/icons';
import { Col, Divider, Row, Tooltip, Typography } from 'antd';
import Title from 'antd/es/typography/Title';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useData, useRates } from '../../../lib/shared/data-context';
import { MainLayout } from '../../components';
import { Routing } from '../../routing';
import { secondaryColor, tertiaryColor } from '../../styles/theme';
import { TarifCard } from './tarif-card';

export const TarifSelectionView: React.FC = () => {
    const navigate = useNavigate();
    const { data, setData } = useData();
    const rates = useRates();

    const [isContinueDisabled, setContinueDisabled] = useState<boolean>(true);

    const goBack = (): void => {
        navigate(Routing.landing);
    };

    const goNext = (): void => {
        navigate(Routing.carDetails);
    };

    const updateSelectedTarif = (value: number): void => {
        setData({ ...data, selectedTarif: value });
    };

    const isTarifDisabled = useCallback(
        (option: number): boolean => {
            const index = option + 1; // 1 = Glückspilz, 2 = Normalo, 3 = Tollpatsch
            const { insuranceOption, comprehensive } = data;
            const isComprehensive = insuranceOption === 3;
            if (insuranceOption === 0) {
                return true;
            }
            if (isComprehensive) {
                if (comprehensive === 0 || comprehensive === 1) {
                    return true;
                }
                if (comprehensive === 2 && index !== 1) {
                    return true;
                }
                if (comprehensive === 3 && index === 3) {
                    return true;
                }
            }
            return false;
        },
        [data]
    );

    useEffect(() => {
        setContinueDisabled(data.selectedTarif === 0);
    }, [data.selectedTarif]);

    useEffect(() => {
        if (rates?.length > 0) {
            const enabledTarifs = rates.filter((_, index) => isTarifDisabled(index) === false);
            if (enabledTarifs.length === 1) {
                setData({ ...data, selectedTarif: rates[0].id });
            }
        }
    }, [data, rates, setData, isTarifDisabled]);

    return (
        <MainLayout
            currentProgress={2}
            showBackButton
            showContinueButton
            showSubmitButton={false}
            continueButtonDisabled={isContinueDisabled}
            goNext={goNext}
            goBack={goBack}
        >
            <div>
                <div style={{ marginBottom: '2rem' }}>
                    <Title className='title left'>{'Wie möchtest du dein Auto schützen?'}</Title>
                    <Typography className='text'>
                        <InfoCircleFilled style={{ color: tertiaryColor }} />
                        {
                            ' Wir sind dein Rabatt-Retter und deine Selbstbehalt-Versicherung. Demzufolge kannst du nur einen Tarif entsprechend deines Selbstbehalts auswählen.'
                        }
                    </Typography>
                </div>
                <Row>
                    {rates.map((rate, i) => {
                        const { selectedTarif } = data;
                        const disabled = isTarifDisabled(i);
                        return (
                            <Col className='welcome-view-column' flex={1} key={rate.id}>
                                <TarifCard
                                    disabled={disabled}
                                    selected={selectedTarif === rate.id}
                                    onSelect={(): void => updateSelectedTarif(rate.id)}
                                >
                                    <Typography.Title className='welcome-view-card-title' level={3}>
                                        {rate.name}
                                    </Typography.Title>
                                    {disabled && (
                                        <Tooltip title='Wir sind dein Rabatt-Retter und deine Selbstbehalt-Versicherung. Demzufolge kannst du nur einen Tarif entsprechend deines Selbstbehalts auswählen.'>
                                            <InfoCircleFilled
                                                style={{
                                                    color: tertiaryColor,
                                                    fontSize: '20px',
                                                    position: 'absolute',
                                                    top: '2rem',
                                                    right: '2rem',
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                    <div
                                        className='display-circle'
                                        style={{ marginBottom: '3rem' }}
                                    >
                                        <div />
                                        {disabled ? (
                                            <Typography.Title
                                                className='welcome-view-card-title'
                                                level={2}
                                            >
                                                {rate.description2}
                                            </Typography.Title>
                                        ) : (
                                            <Typography.Title
                                                className='welcome-view-card-title'
                                                level={2}
                                                style={{ color: tertiaryColor }}
                                            >
                                                {rate.description2}
                                            </Typography.Title>
                                        )}
                                    </div>
                                    {disabled ? (
                                        <Typography className='welcome-view-list-item'>
                                            {`${rate.monthlyGross} € pro Monat`}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            className='welcome-view-list-item'
                                            style={{ color: secondaryColor }}
                                        >
                                            {`${rate.monthlyGross} € pro Monat`}
                                        </Typography>
                                    )}
                                    <Divider className='welcome-view-list-divider' />
                                    <Typography className='welcome-view-list-item'>
                                        {rate.description1}
                                    </Typography>
                                    <Divider className='welcome-view-list-divider' />
                                    <Typography className='welcome-view-list-item'>
                                        {rate.description3}
                                    </Typography>
                                </TarifCard>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        </MainLayout>
    );
};
