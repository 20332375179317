export const isNumber = (value: string): boolean => {
    return !value || !!Number(value) || /^0*$/.test(value);
};

export const isLetter = (value: string): boolean => {
    for (let i = 0; i < value.length; ++i)
        if (value[i].toLowerCase() === value[i].toUpperCase()) return false;
    return true;
};

export const isAlphaNumeric = (value: string): boolean => {
    for (let i = 0; i < value.length; ++i)
        if (!isNumber(value[i]) && !isLetter(value[i])) return false;
    return true;
};

export const isName = (value: string): boolean => {
    if (value.length > 255) return false;
    for (let i = 0; i < value.length; ++i)
        if (!isNumber(value[i]) && !isLetter(value[i]) && !(value[i] === '-')) return false;
    return true;
};

export const isDate = (value: string): boolean => {
    if (
        value.length > 10 ||
        !/^(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})$/.test(value)
    ) {
        return false;
    }

    const segments = value.split('.');
    const day = parseInt(segments[0]);
    const month = parseInt(segments[1]) - 1;
    const year = parseInt(segments[2]);
    const date = new Date(year, month, day);

    const formatDate = (day: number, month: number, year: number): string => {
        let formatted = '';
        if (day < 10) {
            formatted += '0';
        }

        formatted += `${day}.`;
        if (month < 11) {
            formatted += '0';
        }

        formatted += `${month + 1}.${year}`;
        return formatted;
    };

    const dateStringified = formatDate(date.getDate(), date.getMonth(), date.getFullYear());
    const normalizedValue = formatDate(day, month, year);

    return dateStringified === normalizedValue;
};

export const isPhoneNumber = (value: string): boolean => {
    if (value.length > 15) return false;
    for (let index = 0; index < value.length; ++index) {
        const char = value.charAt(index);
        if (!/^[\d\\(\\)\- \\+\\/]$/.test(char)) {
            return false;
        }
    }
    return true;
};

// isCity
export const isStreetAndNumber = (value: string): boolean => {
    if (value.length > 255) return false;
    for (let i = 0; i < value.length; ++i)
        if (
            !isNumber(value[i]) &&
            !isLetter(value[i]) &&
            !(value[i] === ' ') &&
            !(value[i] === '-') &&
            !(value[i] === '.')
        )
            return false;

    return true;
};

export const isEmail = (value: string | null): boolean => {
    return (
        !!value && // eslint-disable-next-line
        /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(
            value
        )
    );
};

export const isNumberPlate = (value: string | null): boolean => {
    return (
        !!value &&
        /^[A-ZÖÜÄ]{1,3}[- ]{1}[A-ZÖÜÄ]{1,2}[ ]{0,1}[1-9]{1}[0-9]{0,3}[ ]{0,1}[EH]{0,1}$/.test(value)
    );
};

export const isPartialNumberPlate = (value: string): boolean => {
    return (
        !value ||
        /^[A-ZÖÜÄ]{1,3}[- ]{0,1}[A-ZÖÜÄ]{0,2}[ ]{0,1}[1-9]{0,1}[0-9]{0,3}[ ]{0,1}[EH]{0,1}$/.test(
            value
        )
    );
};
