import { Button, Modal } from 'antd';
import { AxiosInstance } from 'axios';
import { useEffect, useState } from 'react';
import { useAxios } from '../../../../lib/axios';
import {
    CarResult,
    CarSearchDto,
    CarSearchResultDto,
    ChoosableParametersDto,
} from '../../../../lib/shared/types';
import { DropdownTile } from '../../../components';
import './car-search-popup.scss';

export const CarSearchPopup: React.FC<Props> = ({
    searchParameters,
    setSearchParameters,
    onChooseCar,
}: Props) => {
    const { manufacturer, type, poweredBy, constructionYear, performanceKW } = searchParameters;

    const [choosableParameters, setChoosableParameters] = useState<ChoosableParametersDto>({
        manufacturers: [],
        types: [],
        poweredBys: [],
        constructionYears: [],
        performanceKWs: [],
    });
    const { manufacturers, types, poweredBys, constructionYears, performanceKWs } =
        choosableParameters;
    const [foundCars, setFoundCars] = useState<CarResult[]>([]);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [isShowCarResults, setShowCarResults] = useState<boolean>(false);

    const axios = useAxios();
    useEffect(() => {
        fetchChoosableParameters(axios, searchParameters, setChoosableParameters);
        fetchMatchingCar(axios, searchParameters, setFoundCars);
    }, [axios, searchParameters, setChoosableParameters, setFoundCars]);

    useEffect(() => {
        clearNotFittingParameters(searchParameters, choosableParameters, setSearchParameters);
    }, [searchParameters, choosableParameters, setSearchParameters]);

    return (
        <>
            <button
                className='search-button text link'
                onClick={(): void => setOpen(true)}
                tabIndex={-1}
                style={{ gridArea: 'search', marginTop: '12px' }}
            >
                <span>
                    <img src='/static/images/auto HSN TSN.svg' alt=''></img>
                    <span>{'HSN/TSN über Fahrzeugsuche ermitteln'}</span>
                </span>
            </button>
            <Modal
                open={isOpen}
                onCancel={(): void => setOpen(false)}
                footer={
                    <>
                        {!isShowCarResults && (
                            <Button
                                className='primary-button'
                                type='primary'
                                disabled={foundCars.length < 1}
                                key='submit'
                                onClick={(): void => setShowCarResults(true)}
                            >
                                Ergebnisse anzeigen
                            </Button>
                        )}
                        {isShowCarResults && (
                            <Button
                                className='secondary-button'
                                key='submit'
                                onClick={(): void => setShowCarResults(false)}
                            >
                                Zurück
                            </Button>
                        )}
                    </>
                }
            >
                {!isShowCarResults && (
                    <div className='popup'>
                        <div className='contentTile nobr'>
                            <span className='title'>Fahrzeugsuche </span>
                        </div>
                        <DropdownTile
                            label='Hersteller / Marke'
                            items={manufacturers}
                            selected={manufacturer}
                            placeholder={'Bitte Hersteller auswählen'}
                            onChange={(manufacturer): void =>
                                setSearchParameters({ ...searchParameters, manufacturer })
                            }
                            allowClear
                        />
                        <DropdownTile
                            label='Dein Modell / Typ'
                            items={types}
                            selected={type}
                            placeholder={'Bitte Modell auswählen'}
                            onChange={(type): void =>
                                setSearchParameters({ ...searchParameters, type })
                            }
                            allowClear
                        />
                        <DropdownTile
                            label='Kraftstoff / Antrieb'
                            items={poweredBys}
                            selected={poweredBy}
                            placeholder={'Bitte Kraftstoffart auswählen'}
                            onChange={(poweredBy): void =>
                                setSearchParameters({ ...searchParameters, poweredBy })
                            }
                            allowClear
                        />
                        <DropdownTile
                            label='Baujahr ab'
                            items={constructionYears}
                            selected={constructionYear}
                            placeholder={'Bitte Baujahr auswählen'}
                            onChange={(constructionYear): void =>
                                setSearchParameters({ ...searchParameters, constructionYear })
                            }
                            allowClear
                            label2='Leistung'
                            items2={performanceKWs}
                            selected2={performanceKW}
                            placeholder2={'Bitte Leistung auswählen'}
                            onChange2={(performanceKW): void =>
                                setSearchParameters({ ...searchParameters, performanceKW })
                            }
                            allowClear2
                        />
                    </div>
                )}
                {isShowCarResults && (
                    <div className='popup'>
                        <div className='contentTile nobr'>
                            <span className='title'>Fahrzeugsuche </span>
                        </div>
                        {foundCars.map((car) => {
                            return (
                                <Button
                                    className='selectable-car-button'
                                    onClick={(): void => {
                                        onChooseCar(car.tsn, car.hsn);
                                        setOpen(false);
                                    }}
                                >
                                    <div className='text bold'>
                                        {manufacturer + ' (' + car.specificType + ')'}
                                    </div>
                                    <div className='text'>
                                        {'Leistung: ' +
                                            car.performance +
                                            'kW / Baujahr: ' +
                                            car.year}
                                    </div>
                                    <div className='text'>
                                        {'HSN: ' + car.hsn + ' / TSN: ' + car.tsn}
                                    </div>
                                </Button>
                            );
                        })}
                    </div>
                )}
            </Modal>
        </>
    );
};

interface Props {
    onChooseCar: (tsn: string, hsn: string) => void;
    searchParameters: CarSearchDto;
    setSearchParameters: (parameters: CarSearchDto) => void;
}

const fetchChoosableParameters = async (
    axios: AxiosInstance,
    params: CarSearchDto,
    setChoosableParameters: (newParamLists: ChoosableParametersDto) => void
): Promise<void> => {
    const response = await axios.get<ChoosableParametersDto>('api/car-search-parameters', {
        params,
    });
    response?.data && setChoosableParameters(response.data);
};

const fetchMatchingCar = async (
    axios: AxiosInstance,
    params: CarSearchDto,
    setFoundCars: (car: CarResult[]) => void
): Promise<void> => {
    if (!params?.manufacturer || !params?.type) {
        setFoundCars([]);
        return;
    }
    const cars = (await axios.get<CarSearchResultDto>('api/car-search', { params }))?.data?.cars;
    cars?.forEach((c) => {
        c.specificType ??= '';
        c.performance ??= '';
        c.year ??= '';
        c.tsn ??= '';
        c.hsn ??= '';
    });
    if (cars) {
        setFoundCars(cars);
    } else {
        setFoundCars([]);
    }
};

const clearNotFittingParameters = (
    searchParameters: CarSearchDto,
    choosableParameters: ChoosableParametersDto,
    setSearchParameters: (parameters: CarSearchDto) => void
): void => {
    const { type, constructionYear, performanceKW, poweredBy } = searchParameters;
    const { types, constructionYears, performanceKWs, poweredBys } = choosableParameters;
    if (type && !types.includes(type)) {
        setSearchParameters({ ...searchParameters, type: null });
    }
    if (poweredBy && !poweredBys.includes(poweredBy)) {
        setSearchParameters({ ...searchParameters, poweredBy: null });
    }
    if (constructionYear && !constructionYears.includes(constructionYear)) {
        setSearchParameters({ ...searchParameters, constructionYear: null });
    }
    if (performanceKW && !performanceKWs.includes(performanceKW)) {
        setSearchParameters({ ...searchParameters, performanceKW: null });
    }
};
