import { InfoCircleFilled } from '@ant-design/icons';
import { CSSProperties, ReactNode } from 'react';
import '../../styles/shared.scss';
import { tertiaryColor } from '../../styles/theme';
import './dotted-info-box.scss';

interface Props {
    className?: string | undefined;
    children?: ReactNode;
    style?: CSSProperties | undefined;
}

export const DottedInfoBox: React.FC<Props> = ({ className, children, style }: Props) => {
    return (
        <div className='dotted-info-box' style={style}>
            <InfoCircleFilled
                className='dotted-info-box-icon'
                style={{ color: tertiaryColor, fontSize: '3rem' }}
            />
            <div className={`dotted-info-box-content ${className}`}>{children}</div>
        </div>
    );
};
