import { AxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
import { isDevelopment } from '../../environment';
import { useAxios } from './use-axios';

export const usePathInterceptor = (): void => {
    const axios = useAxios();

    useEffect(() => {
        const requestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
            config.baseURL = isDevelopment ? config.baseURL : window.location.origin;
            config.url = !config.url?.startsWith('api') ? `api/${config.url}` : config.url;
            return config;
        };

        axios.interceptors.request.use(requestInterceptor);
    }, [axios.interceptors.request]);
};
