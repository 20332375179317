import { Card, Divider } from 'antd';
import Title from 'antd/es/typography/Title';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useData } from '../../../lib/shared/data-context';
import { DottedInfoBox, MainLayout, RequiredCheckbox } from '../../components';
import { Routing } from '../../routing';
import '../../styles/shared.scss';
import './questions-view.scss';

export const QuestionsView: React.FC = () => {
    const navigate = useNavigate();
    const { data, setData } = useData();

    const [isContinueDisabled, setContinueDisabled] = useState<boolean>(true);

    const goBack = (): void => {
        navigate(Routing.carDetails);
    };

    const goNext = (): void => {
        navigate(Routing.userDetails);
    };

    const isAccepted = useMemo<Question[]>(
        () => [
            {
                label: 'Dein Fahrzeug ist ein PKW?',
                uncheckedHint: 'Leider versichern wir aktuell nur PKWs!',
            },
            {
                label: 'Du bist der Halter des PKW?',
                uncheckedHint: 'Für diese Versicherung musst Du der Halter sein!',
            },
            {
                label: 'Deine aktuelle Versicherung läuft auf deinen Namen?',
                uncheckedHint:
                    'Für diese Versicherung muss die KFZ-Versicherung auf deinen Namen laufen!',
            },
            {
                label: 'Dein PKW hat keinen unreparierten Schaden?',
                uncheckedHint:
                    'Um diese Versicherung abzuschließen, dürfen keine unreparierten Schäden vorhanden sein.',
            },
        ],
        []
    );

    useEffect(() => {
        setContinueDisabled(
            !data.truthfulStatement ||
                !data.isAccepted ||
                !data.isAccepted.every((_, key) => data.isAccepted[key])
        );
    }, [data.isAccepted, data.truthfulStatement, data, isAccepted]);

    return (
        <MainLayout
            currentProgress={4}
            showBackButton
            showContinueButton
            showSubmitButton={false}
            continueButtonDisabled={isContinueDisabled}
            goNext={goNext}
            goBack={goBack}
        >
            <div>
                <Title className='title left'>
                    {'Bitte beantworte uns noch die wichtigen Fragen'}
                </Title>
                <Card className='container-card' style={{ marginBottom: '24px' }}>
                    {data.isAccepted.map((_, index) => {
                        return (
                            <div>
                                <RequiredCheckbox
                                    className='questions-view-inner-checkbox'
                                    checked={data.isAccepted[index]}
                                    onChange={(checked): void => {
                                        const newData = data.isAccepted;
                                        newData[index] = checked;
                                        setData({
                                            ...data,
                                            isAccepted: newData,
                                        });
                                    }}
                                >
                                    <div className={'text'}>{isAccepted[index].label}</div>
                                </RequiredCheckbox>
                                {index < data.isAccepted.length - 1 && <Divider />}
                            </div>
                        );
                    })}
                </Card>
                <div>
                    {!data.isAccepted.every((flag) => flag) && (
                        <DottedInfoBox style={{ marginBottom: '2rem' }}>
                            <div className='centered'>
                                <h3 className='title'>
                                    {'Leider können wir dir keine Versicherung anbieten!'}
                                </h3>
                                {data.isAccepted.map((_, index) => {
                                    if (data.isAccepted[index]) {
                                        return <></>;
                                    }
                                    return <div>{isAccepted[index].uncheckedHint}</div>;
                                })}
                            </div>
                        </DottedInfoBox>
                    )}
                </div>
                <RequiredCheckbox
                    className='question-outside-checkbox'
                    checked={data.truthfulStatement}
                    onChange={(checked): void => setData({ ...data, truthfulStatement: checked })}
                >
                    <div className={'text'}>
                        {
                            'Hiermit bestätige ich, dass meine oben gemachten Angaben der Wahrheit entsprechen - insbesondere, dass mein PKW keine unreparierten Schaden aufweist.'
                        }
                    </div>
                </RequiredCheckbox>
            </div>
        </MainLayout>
    );
};

interface Question {
    label: string;
    uncheckedHint: string;
}
