import Title from 'antd/es/typography/Title';
import { useEffect } from 'react';
import { useData } from '../../../lib/shared/data-context';
import './result-view.scss';

export const ResultView: React.FC<Props> = ({ isAccepted }: Props) => {
    const { reset } = useData();

    useEffect(() => {
        reset();
    }, [reset]);

    if (isAccepted) {
        return (
            <div>
                <img
                    className='result-image'
                    alt='Konfetti'
                    src='static/images/Konfetti-AbschlussSeite.png'
                    width={'100%'}
                />
                <div className='contentTile result-text'>
                    <Title className='result-title left'>
                        {'Glückwunsch zur richtigen Entscheidung!'}
                    </Title>
                    <div className='result-text' style={{ marginTop: '24px' }}>
                        {'Du bekommst in Kürze deine Bestätigung per E-Mail.'}
                        <br />
                        {'Wir wünschen dir eine gute Fahrt!'}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className='contentTile' style={{ marginTop: '160px' }}>
                <Title className='result-title left'>{'Es tut uns leid!'}</Title>
                <div className='result-text' style={{ marginTop: '-12px' }}>
                    {'Wir können dir derzeit leider keine Mini-Kasko-Versicherung anbieten.'}
                    <br />
                    {'Wir wünschen dir weiterhin eine gute Fahrt.'}
                </div>
            </div>
        );
    }
};

interface Props {
    isAccepted: boolean;
}
