import { ReactNode } from 'react';
import './summary-card.scss';

export const SummaryCard: React.FC<Props> = ({ children, title }: Props) => {
    return (
        <div className='summary-card'>
            <span className='summary-card-header'>{title}</span>
            {children}
        </div>
    );
};

interface Props {
    children?: ReactNode;
    title: string;
}
