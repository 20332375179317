import { Card, Divider } from 'antd';
import Title from 'antd/es/typography/Title';
import { useNavigate } from 'react-router';
import { useData, useRates } from '../../../lib/shared/data-context';
import { getInsurance } from '../../../lib/shared/types';
import { MainLayout } from '../../components';
import { Routing } from '../../routing';
import { tertiaryColor } from '../../styles/theme';
import { SummaryCard } from './summary-card';
import './summary-view.scss';

export const SummaryView: React.FC = () => {
    const navigate = useNavigate();
    const { data } = useData();
    const rates = useRates();

    const goBack = (): void => {
        navigate(Routing.ibanInput);
    };

    const goNext = (): void => {
        navigate(Routing.submission);
    };

    const tarifOption = rates.find((rate) => rate.id === data.selectedTarif);

    return (
        <MainLayout
            currentProgress={7}
            showBackButton
            showContinueButton
            showSubmitButton={false}
            continueButtonDisabled={false}
            goNext={goNext}
            goBack={goBack}
        >
            <div className='summary-view'>
                <Card className='info-card'>
                    <div className='centered'>
                        <Title
                            className='bigger'
                            style={{ color: tertiaryColor, textAlign: 'center' }}
                        >
                            {'Deine Zusammenfassung'}
                        </Title>
                        <div className='text'>
                            {'Bitte '}
                            <b>{'prüfe deine Angaben'}</b>
                            {' noch einmal bevor wir mit der Bearbeitung deines Antrags beginnen.'}
                        </div>
                    </div>
                </Card>
                <SummaryCard title='Deine neue Versicherung'>
                    <div className='rate-card-content'>
                        <div className='rate-card-content-items'>
                            <div className='text' style={{ fontSize: '32px' }}>
                                <span className='bold'>{tarifOption?.name}</span>
                                {' Tarif'}
                            </div>
                            <br />
                            <div className='text'>
                                <span className='bold'>{'Prämie: '}</span>
                                {` ${tarifOption?.monthlyGross} € pro Monat`}
                            </div>
                            <Divider className='summary-card-divider' />
                            <div className='text bold'>{'Was ist abgesichert?'}</div>
                            <Divider className='summary-card-divider' />
                            <div className='text'>{tarifOption?.description3}</div>
                            <Divider className='summary-card-divider' />
                            <div className='text'>{tarifOption?.description2}</div>
                            <Divider className='summary-card-divider' />
                            <div className='text'>{tarifOption?.description1}</div>
                            <Divider className='summary-card-divider' />
                        </div>
                        <div>
                            <div className='display-circle2'>
                                <div />
                                <div
                                    className='text bold centered'
                                    style={{ fontSize: '40px', margin: 'auto' }}
                                >{`${tarifOption?.monthlyGross} €`}</div>
                                <div className='text centered' style={{ margin: 'auto' }}>
                                    {'pro Monat'}
                                </div>
                            </div>
                        </div>
                    </div>
                </SummaryCard>
                <SummaryCard title='Deine aktuelle Versicherung'>
                    <div className='rate-card-content'>
                        <div className='rate-card-content-items'>
                            <div className='text'>
                                <span className='bold'>{'Aktuelle Versicherung: '}</span>
                                {getInsurance(data.insuranceOption)}
                            </div>
                            <Divider className='summary-card-divider' />
                            <div className='text'>
                                <span className='bold'>{'Selbstbeteiligung: '}</span>
                                {tarifOption?.description2}
                            </div>
                            <Divider className='summary-card-divider' />
                            <div className='text'>
                                <span className='bold'>{'Aktueller Versicherer: '}</span>
                                {data.currentInsurance}
                            </div>
                            <Divider className='summary-card-divider' />
                        </div>
                    </div>
                </SummaryCard>
                <SummaryCard title='Deine Fahrzeugdaten'>
                    <div className='rate-card-content'>
                        <div className='rate-card-content-items'>
                            <div className='text'>
                                <span className='bold'>{'Herstellernummer: '}</span>
                                {data.manufactureNr}
                            </div>
                            <Divider className='summary-card-divider' />
                            <div className='text'>
                                <span className='bold'>{'Typschlüsselnummer: '}</span>
                                {data.typeNr}
                            </div>
                            <Divider className='summary-card-divider' />
                            <div className='text'>
                                <span className='bold'>{'Fahrzeugidentifikationsnummer: '}</span>
                                {data.carId}
                            </div>
                            <Divider className='summary-card-divider' />
                            <div className='text'>
                                <span className='bold'>{'Amtliches Kennzeichen: '}</span>
                                {data.numberPlate}
                            </div>
                            <Divider className='summary-card-divider' />
                        </div>
                    </div>
                </SummaryCard>
                <SummaryCard title='Deine persönlichen Daten'>
                    <div className='rate-card-content'>
                        <div className='rate-card-content-items'>
                            <div className='text'>
                                <span className='bold'>{'Anrede: '}</span>
                                {data.salutation}
                            </div>
                            <Divider className='summary-card-divider' />
                            <div className='text'>
                                <span className='bold'>{'Vorname: '}</span>
                                {data.firstName}
                            </div>
                            <Divider className='summary-card-divider' />
                            <div className='text'>
                                <span className='bold'>{'Nachname: '}</span>
                                {data.lastName}
                            </div>
                            <Divider className='summary-card-divider' />
                            <div className='text'>
                                <span className='bold'>{'Geburtsdatum: '}</span>
                                {data.birthday}
                            </div>
                            <Divider className='summary-card-divider' />
                            <div className='text'>
                                <span className='bold'>{'Straße: '}</span>
                                {data.streetAndNumber}
                            </div>
                            <Divider className='summary-card-divider' />
                            <div className='text'>
                                <span className='bold'>{'Ort: '}</span>
                                {data.city}
                            </div>
                            <Divider className='summary-card-divider' />
                            <div className='text'>
                                <span className='bold'>{'Postleitzahl: '}</span>
                                {data.zipCode}
                            </div>
                            <Divider className='summary-card-divider' />
                            <div className='text'>
                                <span className='bold'>{'Telefonnummer: '}</span>
                                {data.phoneNumber}
                            </div>
                            <Divider className='summary-card-divider' />
                            <div className='text'>
                                <span className='bold'>{'E-Mail: '}</span>
                                {data.email}
                            </div>
                            <Divider className='summary-card-divider' />
                        </div>
                    </div>
                </SummaryCard>
                <SummaryCard title='Deine Bankverbindung'>
                    <div className='rate-card-content'>
                        <div className='rate-card-content-items'>
                            <div className='text'>
                                <span className='bold'>{'Kontoinhaber: '}</span>
                                {`${data.firstName} ${data.lastName}`}
                            </div>
                            <Divider className='summary-card-divider' />
                            <div className='text'>
                                <span className='bold'>{'Bank: '}</span>
                                {data.bank}
                            </div>
                            <Divider className='summary-card-divider' />
                            <div className='text'>
                                <span className='bold'>{'IBAN des Kontos: '}</span>
                                {data.iban}
                            </div>
                            <Divider className='summary-card-divider' />
                        </div>
                    </div>
                </SummaryCard>
            </div>
        </MainLayout>
    );
};
