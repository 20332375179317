import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ReactNode } from 'react';
import './required-checkbox.scss';

interface Props {
    className?: string | undefined;
    checked?: boolean | undefined;
    children?: ReactNode;
    onChange?: (newValue: boolean) => void | Promise<void>;
}

export const RequiredCheckbox: React.FC<Props> = ({
    className,
    checked,
    children,
    onChange,
}: Props) => {
    let classes = 'required-checkbox';
    if (className) {
        classes += ` ${className}`;
    }

    const changed = (event: CheckboxChangeEvent): void => {
        if (onChange) {
            onChange(event.target.checked);
        }
    };

    return (
        <Checkbox className={classes} checked={checked} onChange={changed}>
            {children}
        </Checkbox>
    );
};
