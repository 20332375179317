import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useAxios } from '../../axios';

type Props = {
    children?: ReactNode;
};

export function InsuranceListProvider(props: Props): JSX.Element {
    const axios = useAxios();
    const [insuranceList, setInsuranceList] = useState<string[]>([]);

    const fetch = useCallback(async (): Promise<void> => {
        const insuranceList = await axios.get<string[]>('api/insurances');
        if (insuranceList?.data) {
            setInsuranceList(insuranceList.data);
        } else {
            setInsuranceList([]);
        }
    }, [axios]);

    useEffect(() => {
        if (insuranceList == null || insuranceList.length === 0) {
            fetch();
        }
    }, [insuranceList, fetch]);

    return (
        <InsuranceListContext.Provider value={insuranceList}>
            {props.children}
        </InsuranceListContext.Provider>
    );
}

export const InsuranceListContext = createContext<string[]>([]);

export function useInsuranceList(): string[] {
    const context = useContext(InsuranceListContext);
    if (context == null) {
        throw new Error('InsuranceListContext.Provider is not set in the React component tree.');
    }
    return context;
}
