export interface TarifDto {
    comprehensive: number;
    insuranceOption: number;
    currentInsurance: string | null;
}

export const getInsurance = (index: number): string => {
    switch (index) {
        case 1:
            return 'Haftpflicht';
        case 2:
            return 'Teilkasko';
        case 3:
            return 'Vollkasko';
        default:
            return 'TarifDoesNotExist';
    }
};

export const getComprehensive = (index: number): string => {
    switch (index) {
        case 1:
            return '0';
        case 2:
            return '300';
        case 3:
            return '500';
        case 4:
            return '1000';
        case 5:
            return '>1000';
        default:
            return 'ComprehensiveDoesNotExist';
    }
};
